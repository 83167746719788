<template>
  <div class="footer">
    <span>
      <p>
        © {{ setCurrentYear }} Make-A-Wish Portugal, todos os direitos
        reservados. Desenvolvido por
        <a href="https://www.blissapplications.com/" target="_blank">
          Bliss Applications
        </a>
      </p>
    </span>
  </div>
</template>

<script>
export default {
  name: "HeaderMenu",
  computed: {
    setCurrentYear() {
      const currentYear = new Date().getFullYear();
      return currentYear;
    }
  }
};
</script>

<style scoped lang="scss">
.footer {
  margin-top: 40px;

  @media (min-width: $breakpoint-lg) {
    margin-top: 130px;
  }

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-make-a-wish-blue;
    width: 100%;
    height: 90px;

    @media (min-width: $breakpoint-lg) {
      height: 120px;
    }

    p {
      font-family: "latoregular";
      font-size: 12px;
      line-height: 14px;
      color: $color-white;
      text-align: center;
      padding: 31px 20px;

      a {
        color: inherit; /* blue colors for links too */
        text-decoration: underline; /* no underline */
      }

      @media (min-width: $breakpoint-lg) {
        max-width: 400px;
        font-size: 14px;
        line-height: 17px;
      }
    }
  }
}
</style>
